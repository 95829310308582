.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--orange)
}

.footer{
    padding: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;
    height: 25rem;
}

.social-links{
    display: flex;
    gap: 3rem;
}

.social-links>img{
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
}

.logo-f{
    width: 15rem;
}

.logo-f>img{
    width: 15rem;
}

.footer-blur-1{
    bottom: 0;
    right: 30%;
    width: 26rem;
    height: 12rem;
    filter: blur(190px);
    background: orangered;

}

.footer-blur-2{
    bottom: 0;
    left: 25%;
    width: 26rem;
    height: 12rem;
    filter: blur(190px);
    background: rgb(255, 115, 0);

}